import { Fragment } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import messages from '../messages'
import { getLanguage } from '@mzaednica/core'
import { LANGUAGES } from './languages'

function Provider({ children, language = LANGUAGES.MK }) {
  return (
    <IntlProvider
      language={language}
      textComponent={Fragment}
      messages={messages[language]}
      onError={() => {}}
    >
      {children}
    </IntlProvider>
  )
}

const mapStateToProps = (state) => ({
  language: getLanguage(state),
})

export default connect(mapStateToProps)(Provider)
