import { useIntl } from 'react-intl'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {
  fetchSubventionClaim,
  getSubventionRequestDetails,
  getUserDetails,
  listSubventionRequestComments,
  createSubventionRequestComment,
  getSubventionRequestComments,
} from '@mzaednica/core'
import withAuthentication from '../../utils/withAuthentication'
import { Field, Form, Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import ProfileIcon from '../../img/profile_menu_gray.svg'
import CameraGray from '../../img/camera_gray.svg'
import MultipleMediaField from '../../components/Inputs/MultipleMediaField'
import findImageWithQuality from '../../utils/findImageWithQuality'

const initialValues = {
  content: '',
  files: [], // Changed to accept files as an array
}

const validationSchema = Yup.object({
  content: Yup.string().required('Comment is required'),
})

const SubventionRequest = ({
  fetchSubventionClaim,
  subventionRequestDetails,
  userDetails,
  listSubventionRequestComments,
  createSubventionRequestComment,
  comments,
}) => {
  let { id } = useParams()
  const history = useHistory()
  const { formatMessage } = useIntl()
  const [request, setRequest] = useState(null)

  const getInitialData = useCallback(async () => {
    await fetchSubventionClaim(id)
    await listSubventionRequestComments({ subventionRequest: id })
  }, [fetchSubventionClaim, id, listSubventionRequestComments])

  useEffect(() => {
    getInitialData()
  }, [getInitialData])

  useEffect(() => {
    setRequest(subventionRequestDetails.subventionRequestData)
  }, [subventionRequestDetails.subventionRequestData])

  const submitComment = useCallback(
    async (values, { resetForm }) => {
      if (!userDetails.userData) {
        history.push('login')
      } else if (!userDetails.userData.profileComplete) {
        history.push('completeProfile')
      } else {
        const commentData = {
          ...values,
          subventionRequest: id,
          commentType: 'comment',
        }
        delete commentData.files
        try {
          await createSubventionRequestComment(values.files[0], commentData)
          resetForm()
          listSubventionRequestComments({ subventionRequest: id })
        } catch (e) {
          console.error(e)
          // Handle error if needed
        }
      }
    },
    [
      userDetails,
      id,
      createSubventionRequestComment,
      listSubventionRequestComments,
      history,
    ],
  )

  return (
    <>
      <Header />
      <section className="bg-lightGray4 py-12">
        <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center">
          {formatMessage({ id: 'inquiry' })}
        </h2>
        <div className="container px-4 md:px-0 pt-10">
          {request &&
            request.fields.map((fld, idx) => {
              switch (fld.__component) {
                case 'fields.text-field':
                case 'fields.rich-text-field':
                  return (
                    <label
                      key={idx}
                      className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5"
                    >
                      <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                        {fld.fieldLabel}
                        {fld.isRequired ? '*' : ''}
                      </span>
                      <div className="inline-flex items-center justify-between">
                        {fld.value}
                      </div>
                    </label>
                  )
                case 'fields.media-field':
                  return (
                    <div key={idx}>
                      {fld.fieldLabel}
                      <img
                        alt="someAlt"
                        className="h-64"
                        src={`${process.env.RAZZLE_API_URL}${fld.value.url}`}
                      />
                    </div>
                  )
                case 'fields.multiple-media-field':
                  const component = fld.value
                    ? fld.value.map((val, valIdx) => (
                        <div key={valIdx}>
                          {val.mime.startsWith('image/') ? (
                            <a href={`${process.env.RAZZLE_API_URL}${val.url}`}>
                              <img
                                alt="someAlt"
                                className="w-20 h-20"
                                src={`${process.env.RAZZLE_API_URL}${val.url}`}
                              />
                            </a>
                          ) : (
                            <>
                              <a
                                className="text-blue"
                                href={`${process.env.RAZZLE_API_URL}${val.url}`}
                              >{`${val.name}`}</a>
                              <a
                                href={`${process.env.RAZZLE_API_URL}${val.url}`}
                                download
                                target="_blank"
                                className="text-blue ml-10"
                                rel="noreferrer"
                              >
                                {formatMessage({ id: 'downloadDoc' })}
                              </a>
                            </>
                          )}
                          <br />
                        </div>
                      ))
                    : ''
                  return (
                    <div key={idx}>
                      {fld.fieldLabel}
                      {component}
                    </div>
                  )
                default:
                  return null
              }
            })}
          <div>{formatMessage({ id: 'iAgreeMuniCopyIDPersonalData' })}</div>
        </div>
      </section>

      <div className="container max-w-4xl mx-auto mb-4 font-montserratRegular">
        <section className="px-5 md:px-0 pt-7">
          {/* User Info */}
          <div className="flex h-full">
            <div className="flex flex-col">
              <div className="w-94 h-auto inline-flex items-center justify-center">
                <figure className="bg-avatarIcon rounded-full w-16 h-16 flex justify-center items-center flex-shrink-0">
                  <img
                    src={
                      request && request.user && request.user.profilePicture
                        ? `${process.env.RAZZLE_API_URL}${findImageWithQuality(
                            request.user.profilePicture,
                          )}`
                        : ProfileIcon
                    }
                    alt="User icon"
                    className="object-cover w-16 h-16 p-1 rounded-full"
                  />
                </figure>
              </div>
              {comments.isFetched && !!comments.listData.length && (
                <div className="w-94 flex h-full min-h-40 justify-center">
                  <div className="bg-blue w-2.5"></div>
                </div>
              )}
            </div>
            <div className="ml-5 md:ml-10">
              <h6 className="text-base font-semibold text-lightGray3">
                {formatMessage({ id: 'submittedBy' })}
              </h6>
              <h4 className="font-semibold text-darkGray1 text-lg">
                {request &&
                  request.user &&
                  `${request.user.firstName} ${request.user.familyName}`}
              </h4>
              <h6 className="text-base font-semibold text-lightGray3">
                {request && moment(request.created_at).format('DD.MM.YYYY')}
              </h6>
            </div>
          </div>

          {!comments.isFetching &&
            comments.isFetched &&
            comments.listData.map((comment, idx) => {
              const isLast = idx === comments.listData.length - 1
              return (
                <div className="flex h-full" key={idx}>
                  <div className="flex flex-col">
                    <div className="w-94 h-auto inline-flex items-center justify-center">
                      <figure className="bg-avatarIcon rounded-full w-16 h-16 flex justify-center items-center flex-shrink-0">
                        <img
                          src={
                            comment.user && comment.user.profilePicture
                              ? `${
                                  process.env.RAZZLE_API_URL
                                }${findImageWithQuality(
                                  comment.user.profilePicture,
                                )}`
                              : ProfileIcon
                          }
                          alt="User icon"
                          className="object-cover w-16 h-16 p-1 rounded-full"
                        />
                      </figure>
                    </div>
                    {!isLast && (
                      <div className="w-94 flex h-full min-h-40 justify-center">
                        <div className="bg-blue w-2.5"></div>
                      </div>
                    )}
                  </div>
                  <div className="ml-5 md:ml-10">
                    <h4 className="font-semibold text-darkGray1 text-lg">
                      {comment.user.firstName} {comment.user.familyName}
                    </h4>
                    <h6 className="text-base font-semibold text-lightGray3">
                      {moment(comment.created_at).format('DD.MM.YYYY')}
                    </h6>
                    <p className="text-base font-semibold text-lightGray3 my-3">
                      {comment.content}
                    </p>
                    {comment.commentType === 'comment' &&
                      !!comment.files.length && (
                        <div className="flex flex-row justify-start items-start mt-5 mb-5 px-4 md:px-0">
                          {comment.files.map((file, fileIdx) => (
                            <img
                              key={fileIdx}
                              src={`${
                                process.env.RAZZLE_API_URL
                              }${findImageWithQuality(file, 'large')}`}
                              alt="Attachment"
                              className="md:w-64 md:h-64 mr-3"
                            />
                          ))}
                        </div>
                      )}
                  </div>
                </div>
              )
            })}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitComment}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col mb-10 flex-wrap md:flex-nowrap px-5 mt-7">
                <Field name="files">
                  {({ field, form: { setFieldValue } }) => (
                    <MultipleMediaField
                      field={field}
                      setFieldValue={setFieldValue}
                      multiple={false}
                      id="files"
                    />
                  )}
                </Field>
                <div className="flex flex-row">
                  <label
                    htmlFor="files"
                    className="flex flex-col justify-center"
                  >
                    <img
                      src={CameraGray}
                      alt="Camera icon"
                      width="54"
                      height="auto"
                    />
                  </label>
                  <label className="grid grid-cols-2 bg-lightGray4 rounded-full py-3.5 px-4 w-full md:ml-3 mt-4 md:mt-0">
                    <Field
                      name="content"
                      type="text"
                      className="w-auto text-lg md:text-xl font-semibold bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                      placeholder={formatMessage({ id: 'enterComment' })}
                    />
                    <div className="flex w-auto justify-end items-center">
                      <button
                        type="submit"
                        className="pr-3 text-md font-bold text-blue disabled:opacity-20"
                        disabled={isSubmitting}
                      >
                        {formatMessage({ id: 'send' })}
                      </button>
                    </div>
                  </label>
                </div>
                <ErrorMessage name="content">
                  {(msg) => <div className="text-red text-sm mt-1">{msg}</div>}
                </ErrorMessage>
              </Form>
            )}
          </Formik>
        </section>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  subventionRequestDetails: getSubventionRequestDetails(state),
  userDetails: getUserDetails(state),
  comments: getSubventionRequestComments(state),
})

const mapDispatchToProps = {
  fetchSubventionClaim,
  listSubventionRequestComments,
  createSubventionRequestComment,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(SubventionRequest),
)
