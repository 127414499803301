import * as yup from 'yup'

export const ReportsCommentSchema = yup.object().shape({
  content: yup.string().trim().required('invalidInput'),
})

export const CreateReportSchema = yup.object().shape({
  title: yup.string().required('requiredInput'),
  reportType: yup.string().oneOf(['problem', 'suggestion']),
  description: yup.string().required('requiredInput'),
  files: yup
    .array()
    .of(
      yup.mixed().test('fileType', 'allowedFiles', (file) => {
        if (!file) return true
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']
        return allowedTypes.includes(file.type)
      }),
    )
    .required('requiredInput')
    .min(1, 'requiredInput'),
})
