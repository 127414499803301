import { Route, Link } from 'react-router-dom'

const HeaderNavLink = ({
  to,
  children,
  className,
  activeClassName,
  ...rest
}) => {
  const path = typeof to === 'object' ? to.pathname : to
  return (
    <Route
      path={path}
      // eslint-disable-next-line react/no-children-prop
      children={({ match }) => {
        const isActive = !!match
        return (
          <Link
            {...rest}
            className={
              isActive
                ? [className, activeClassName].filter((i) => i).join(' ')
                : className
            }
            to={to}
          >
            {typeof children === 'function' ? children(isActive) : children}
          </Link>
        )
      }}
    />
  )
}

export default HeaderNavLink
