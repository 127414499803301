import { connect } from 'react-redux'
import { isModalOpen, closeModal } from '@mzaednica/core'

const WithConnectedModal = (id, ChildComponent) => {
  const WithConnectedModalComponent = ({ isOpen, closeModal, ...props }) => (
    <ChildComponent
      isOpen={isOpen}
      closeModal={closeModal.bind(null, id)}
      {...props}
    />
  )

  const mapStateToProps = (state) => ({
    isOpen: isModalOpen(state, id),
  })

  const mapDispatchToProps = {
    closeModal,
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithConnectedModalComponent)
}

export default WithConnectedModal
