import * as yup from 'yup'

export const CompleteProfileSchema = yup.object().shape({
  municipality: yup.number().required('requiredInput'),
  accountType: yup.string().required('requiredInput'),
  birth: yup.date().when('accountType', {
    is: (type) => type === 'individual',
    then: yup.date().required('requiredInput'),
    otherwise: yup.date().notRequired(),
  }),
  firstName: yup
    .string()
    .trim()
    .when('accountType', {
      is: (type) => type === 'individual',
      then: yup.string().trim().required('requiredInput'),
      otherwise: yup.string().notRequired(),
    }),
  familyName: yup
    .string()
    .trim()
    .when('accountType', {
      is: (type) => type === 'individual',
      then: yup.string().trim().required('requiredInput'),
      otherwise: yup.string().notRequired(),
    }),
  gender: yup
    .string()
    .trim()
    .when('accountType', {
      is: (type) => type === 'individual',
      then: yup.string().trim().required('requiredInput'),
      otherwise: yup.string().notRequired(),
    }),
  companyName: yup
    .string()
    .trim()
    .when('accountType', {
      is: (type) => type !== 'individual',
      then: yup.string().trim().required('requiredInput'),
      otherwise: yup.string().notRequired(),
    }),
  industry: yup.number().when('accountType', {
    is: (type) => type !== 'individual',
    then: yup.number().required('requiredInput'),
    otherwise: yup.number().notRequired(),
  }),
  interests: yup.array().notRequired(),
})
