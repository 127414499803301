import { useIntl } from 'react-intl'

import moment from 'moment'
import ProcessBlue from '../../img/process_blue.svg'
import FinishedBlue from '../../img/finished_blue.svg'
import avatar from '../../img/nophoto.jpg'
import { Link } from 'react-router-dom'
import findImageWithQuality from '../../utils/findImageWithQuality'
import { LANGUAGES } from '../../i18n/settings/languages'
import { getLanguage } from '@mzaednica/core'
import withAuthentication from 'utils/withAuthentication'
import { connect } from 'react-redux'
import defaultImage from '../../img/app_icon.png'
import { constructAbsoluteAppUrl } from '../../utils/constructAbsoluteAppUrl'
import './styles/SurveyCard.css'

const SurveyCard = ({ survey, language }) => {
  const {
    id,
    title,
    title_sq,
    image,
    dateFrom,
    dateDue,
    municipality,
    status,
    published_at,
  } = survey

  const { formatMessage } = useIntl()

  return (
    <>
      <Link
        to={`/surveyDetails/${id}`}
        className="block max-w-xl w-full px-4 py-18 rounded-xl shadow-card"
      >
        <div className="flex">
          <div className="mr-3.5 flex-shrink-0">
            <img
              src={constructAbsoluteAppUrl(
                findImageWithQuality(municipality.logo),
              )}
              alt="Logo"
              className="border-4 border-blue rounded-full w-10 h-10 md:w-16 md:h-16"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h5 className="text-lg font-semibold">
              {formatMessage({ id: 'municipality' })}{' '}
              {language === LANGUAGES.MK
                ? municipality.name
                : municipality.name_sq}
            </h5>
            <div className="text-darkGray1 text-base align-middle flex flex-wrap">
              <p>
                {moment(dateFrom).format('DD.MM.YYYY')} {' - '}
                {moment(dateDue).format('DD.MM.YYYY')}
              </p>
              <div>
                {published_at && status !== 'planned' && (
                  <div className="ml-2">
                    <img
                      src={status === 'active' ? ProcessBlue : FinishedBlue}
                      alt=""
                      className="w-5 h-5 inline-block align-middle"
                    />
                    <span className="text-blue font-semibold ml-1">
                      {status === 'active'
                        ? formatMessage({ id: 'active2' })
                        : formatMessage({ id: 'unactive' })}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <h4 className="text-sm md:text-base font-semibold uppercase my-2 w-full survey title-survey-card">
          {language === LANGUAGES.MK ? title : title_sq ?? title}
        </h4>

        <div className="w-full h-52 flex items-center justify-center bg-gray-50 ">
          {image ? (
            <img
              className="self-center object-contain w-full h-52"
              src={
                !image
                  ? avatar
                  : constructAbsoluteAppUrl(
                      findImageWithQuality(image, 'small'),
                    )
              }
              alt=""
            />
          ) : (
            <img
              className="h-52 w-full object-contain"
              src={defaultImage}
              alt=""
            />
          )}
        </div>
      </Link>
    </>
  )
}
const mapStateToProps = (state) => ({
  language: getLanguage(state),
})

export default withAuthentication(connect(mapStateToProps, {})(SurveyCard))
