import { useIntl } from 'react-intl'
import { ErrorMessage } from 'formik'

export const TranslatedErrorMessage = ({ name }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="text-red mb-5">
      <ErrorMessage name={name}>
        {(msg) => formatMessage({ id: msg })}
      </ErrorMessage>
    </div>
  )
}
