import { useState, useEffect, useCallback } from 'react'
import Footer from '../components/Footer'
import ReactMarkdown from 'react-markdown'
import contactTxt from '../files/Contact.md'
import Header from '../components/Header'

const About = () => {
  const [markdown, setMarkdown] = useState('')

  const fetchMarkdown = useCallback(async () => {
    const res = await fetch(contactTxt)
    const text = await res.text()
    setMarkdown(text)
  }, [])

  useEffect(() => {
    fetchMarkdown()
  }, [fetchMarkdown])

  return (
    <>
      <Header />
      <ReactMarkdown className="container mx-auto pt-5 pb-10 md">
        {markdown}
      </ReactMarkdown>
      <Footer />
    </>
  )
}
export default About
