/* eslint-disable jsx-a11y/label-has-associated-control */
import { useIntl } from 'react-intl'

import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { connect } from 'react-redux'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import MultipleMediaField from '../../components/Inputs/MultipleMediaField'
import ChatMessages from '../../components/Chat/ChatMessages'
import AdvisorCard from '../../components/Advisor/AdvisorCard'
import CameraWhite from '../../img/camera_white.svg'
import {
  createMessage,
  listMessages,
  getMessages,
  fetchAdvisor,
  getAdvisorDetails,
  getUserDetails,
} from '@mzaednica/core'
import withAuthentication from '../../utils/withAuthentication'
import { NewMessageSchema } from './validation/schema'
import { TranslatedErrorMessage } from '../../components/TranslatedErrorMessage'
const NewMessage = ({
  createMessage,
  listMessages,
  fetchAdvisor,
  messages,
  advisor,
  userDetails,
}) => {
  const { id } = useParams()
  const [errorMessage, setErrorMessage] = useState('')
  const initialValues = {
    content: '',
    files: [],
  }

  useEffect(() => {
    listMessages({
      municipal_advisor: id,
    })
    fetchAdvisor({
      id: id,
    })
  }, [fetchAdvisor, id, listMessages])

  const submitComment = useCallback(
    async (values, { resetForm }) => {
      const encodeVals = {
        ...values,
        municipal_advisor: id,
        author_user: true,
      }
      delete encodeVals.files
      const formData = new FormData()
      values.files.forEach((x) => formData.append('files.files', x))
      formData.append('data', JSON.stringify(encodeVals))
      try {
        await createMessage(formData)
        resetForm()
      } catch (e) {
        console.error(e)
        window.scrollTo(20, 0)
        setErrorMessage('uploadUnsuccessful')
      }
    },
    [createMessage, id],
  )

  const { formatMessage } = useIntl()
  return (
    <>
      <Header />
      {userDetails && userDetails.userData?.isMuted && (
        <div className="flex flex-col relative font-semibold bg-red bg-opacity-50 rounded-lg mb-10 flex-wrap p-7 mx-5 my-5 ">
          {formatMessage({ id: 'muted' }) || 'You are muted'}
        </div>
      )}
      <div className="flex flex-col items-center justify-center">
        <div className="container mt-10 mb-2 printHide">
          {advisor.advisorData && (
            <AdvisorCard advisor={advisor.advisorData} isClickDisabled={true} />
          )}
          <ChatMessages messages={messages.listData} />
        </div>
        <div className="container mt-2 mb-10 printHide">
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={submitComment}
            validationSchema={NewMessageSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="flex flex-row overflow-hidden overflow-x-auto space-x-2">
                  <Field name="files">
                    {({ field, form: { setFieldValue } }) => (
                      <MultipleMediaField
                        field={field}
                        setFieldValue={setFieldValue}
                        id="files"
                      />
                    )}
                  </Field>
                </div>
                <div className="container flex flex-row">
                  <div className="bg-lightGray3 w-24 h-24 rounded-xl flex flex-col justify-center items-center px-4 md:px-0">
                    <label
                      htmlFor="files"
                      className="text-white text-sm md:text-lg md:mt-4"
                    >
                      <img
                        src={CameraWhite}
                        alt="Upload icon"
                        width="60"
                        height="auto"
                        className="mx-auto mb-2"
                      />
                    </label>
                    <ErrorMessage name="files" />
                  </div>
                  <div className="w-3/4">
                    <label className="grid grid-cols-2 bg-lightGray4 rounded-full py-3.5 px-4 w-auto md:ml-3 mt-4 md:mt-0">
                      <Field
                        name="content"
                        component="textarea"
                        rows="2"
                        placeholder={formatMessage({ id: 'commentEnter' })}
                        className="mt-1 ml-5 rounded-lg border-transparent flex-1 appearance-none w-full py-2 px-4 bg-transparent text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                      />
                      <div className="inline-flex w-auto justify-end items-center">
                        <button
                          type="submit"
                          className="pr-3 text-xl font-bold text-blue uppercase disabled:opacity-20"
                          disabled={
                            isSubmitting ||
                            (userDetails && userDetails.userData?.isMuted)
                          }
                        >
                          {formatMessage({ id: 'send' })}
                        </button>
                      </div>
                    </label>
                  </div>
                </div>
                <TranslatedErrorMessage name={'content'} />
              </Form>
            )}
          </Formik>
          {errorMessage && (
            <div className="flex flex-col relative font-semibold bg-red bg-opacity-50 rounded-lg mb-10 flex-wrap p-7 mx-5 my-5 ">
              {formatMessage({ id: errorMessage }) || 'Upload unsuccessful'}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  messages: getMessages(state),
  advisor: getAdvisorDetails(state),
  userDetails: getUserDetails(state),
})

const mapDispatchToProps = {
  createMessage,
  listMessages,
  fetchAdvisor,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(NewMessage),
)
