import { Link } from 'react-router-dom'
import avatar from '../../img/nophoto.jpg'
import findImageWithQuality from '../../utils/findImageWithQuality'

const AdvisorCard = ({ advisor, isClickDisabled }) => {
  const {
    id,
    firstName,
    familyName,
    profilePicture,
    capacity,
    education,
    politicalParty,
  } = advisor

  const CardContent = (
    <div className="flex flex-row">
      <div className="flex-shrink-0">
        <img
          className="h-32 w-full object-cover rounded-xl"
          src={
            !profilePicture
              ? avatar
              : `${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  profilePicture,
                  'small',
                )}`
          }
          alt={`${firstName} ${familyName}`}
        />
      </div>
      <div className="flex flex-col justify-center ml-4 text-lightGray2">
        <h5 className="text-xl font-semibold">
          {firstName} {familyName}
          <span className="font-normal capitalize">
            {capacity ? ` - ${capacity}` : ''}
          </span>
        </h5>
        {education && <h4 className="text-xl font-normal">{education}</h4>}
        {politicalParty && (
          <h4 className="text-xl font-semibold uppercase my-2">
            {politicalParty}
          </h4>
        )}
      </div>
    </div>
  )

  return isClickDisabled ? (
    <div className="block bg-white lg:w-2/3 rounded-xl shadow-card my-8">
      {CardContent}
    </div>
  ) : (
    <Link
      to={`/chat/${id}`}
      className="block bg-white lg:w-2/3 rounded-xl shadow-card my-8"
    >
      {CardContent}
    </Link>
  )
}

export default AdvisorCard
