import { useEffect } from 'react'
import withAuthentication from '../../utils/withAuthentication'
import Footer from '../../components/Footer'
import NewsCard from '../../components/News/NewsCard'
import { connect } from 'react-redux'
import { listPosts, getPosts, getLanguage } from '@mzaednica/core'
import Header from '../../components/Header'

const News = ({ listPosts, postList, language }) => {
  useEffect(() => {
    listPosts({ _sort: 'updated_at:DESC' })
  }, [listPosts])

  return (
    <>
      <Header />
      <section className="pt-8 mb-20">
        <div className="container max-w-max gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {postList.listData.map((item, idx) => (
            <NewsCard key={idx} news={item} language={language}></NewsCard>
          ))}
        </div>
      </section>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  postList: getPosts(state),
  language: getLanguage(state),
})

const mapDispatchToProps = {
  listPosts,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(News),
)
