import * as yup from 'yup'
import { passwordRegex } from '../../../../utils/regex/passwordRegex'

export const SignupSchema = yup.object().shape({
  email: yup.string().trim().email('invalidEmail').required('RequiredInput'),
  password: yup
    .string()
    .min(6, 'min6Chars')
    .max(70, 'passwordTooLong')
    .matches(passwordRegex, 'passwordMustContain')
    .required('RequiredInput'),
  passwordConfirmation: yup
    .string()
    .min(6, 'min6Chars')
    .max(70, 'passwordTooLong')
    .matches(passwordRegex, 'passwordMustContain')
    .oneOf([yup.ref('password'), null], 'identicalPass')
    .required('RequiredInput'),
})
