import { useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  setLanguage,
  getLanguages,
  getCurrentMunicipality,
} from '@mzaednica/core'
import logo from '../img/logo.svg'

const ChooseLanguage = ({ languages, setLanguage, currentMunicipality }) => {
  const formSubmit = async (values) => {
    setLanguage({ language: values.language })
    history.push('/home')
  }
  const history = useHistory()

  useEffect(() => {
    if (!currentMunicipality) history.push('/')
  }, [currentMunicipality, history])

  return (
    <div className="flex flex-col items-center container mx-auto mt-5">
      <figure>
        <img src={logo} alt="mZaednica logo" width="180" height="auto" />
      </figure>
      <Formik
        initialValues={{}}
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={formSubmit}
      >
        <Form>
          <div className="">
            <div className="mt-10">
              <div className="relative flex flex-col items-center justify-center">
                <label htmlFor="language" className="text-gray-700">
                  Одбери јазик
                </label>
                <Field
                  as="select"
                  name="language"
                  className="w-48 text-lg md:text-xl text-F bg-transparent border-2 border-lightGray1 rounded-lg focus:border-lightGray1 focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0"
                  placeholder="..."
                >
                  <option value=""></option>
                  {languages &&
                    languages.map((language) => (
                      <option value={language.label} key={language.id}>
                        {language.label}
                      </option>
                    ))}
                </Field>
              </div>
            </div>
            <div className="w-48 mt-16 mb-20 block mx-auto m-0 text-center">
              <button
                type="submit"
                className="py-2 px-4 flex justify-center items-center bg-green hover:bg-green focus:green-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              >
                Продолжи
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

const mapStateToProps = (state) => ({
  languages: getLanguages(state),
  currentMunicipality: getCurrentMunicipality(state),
})

const mapDispatchToProps = {
  setLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLanguage)
