import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import withAuthentication from '../../utils/withAuthentication'
import CreateEnrolmentRequest from '../../components/Enrolments/CreateEnrolmentRequest'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { getChosenDate, getSubmitionType } from '@mzaednica/core'

const CreateSchoolEnrolmentRequest = ({ chosenDate, submitionType }) => {
  const { id, claimId } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (!chosenDate || !submitionType) {
      history.push('/services')
      return
    }
  }, [chosenDate, history, submitionType])

  return (
    <>
      <Header />
      <CreateEnrolmentRequest
        id={id}
        claimId={claimId}
        history={history}
        chosenDate={chosenDate}
        submitionType={submitionType}
      />
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  chosenDate: getChosenDate(state),
  submitionType: getSubmitionType(state),
})

const mapDispatchToProps = () => ({})

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(CreateSchoolEnrolmentRequest),
)
