import { useEffect } from 'react'
import { connect } from 'react-redux'
import withAuthentication from '../../utils/withAuthentication'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import AdvisorCard from '../../components/Advisor/AdvisorCard'
import { listUsers, getUsers } from '@mzaednica/core'

const Advisors = ({ listUsers, userList }) => {
  useEffect(() => {
    listUsers({
      'role.type': 'municipal_advisor',
      _sort: 'updated_at:DESC',
    })
  }, [listUsers])

  return (
    <>
      <Header />
      <section className="pt-8 pb-40">
        <div className="container flex flex-col justify-center gap-30 px-4 lg:px-0">
          {userList.listData.map((item, idx) => (
            <AdvisorCard advisor={item} key={idx} />
          ))}
        </div>
      </section>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  userList: getUsers(state),
})

const mapDispatchToProps = {
  listUsers,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(Advisors),
)
