import { Doughnut } from 'react-chartjs-2'
import { generateRandomColor } from '../../utils/colorUtils'
import { useIntl } from 'react-intl'

export const SingleChoiceQuestionStatistics = ({ question }) => {
  const { formatMessage } = useIntl()

  const labels = question.options.map(
    (option) => `${option.option} (${option.percentage})`,
  )
  const data = question.options.map((option) => option.count)

  const dynamicColors = generateRandomColor(question.options.length)

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Responses',
        backgroundColor: dynamicColors,
        data,
      },
    ],
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full mb-12">
      <div className="text-lg mt-10">{`${formatMessage({
        id: 'question',
      })}${question.question}`}</div>
      <div className="text-sm text-gray-600 mb-4">
        {formatMessage({ id: 'responsesSum' })} {question.totalResponses}
      </div>
      <div className="flex justify-center items-center mx-auto h-96 w-96">
        <Doughnut
          data={chartData}
          options={{
            maintainAspectRatio: true,
            legend: { display: true, position: 'right' },
          }}
          width={300}
          height={300}
        />
      </div>
    </div>
  )
}
