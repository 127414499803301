import { useIntl } from 'react-intl'

import { Link } from 'react-router-dom'
import Header from '../components/Header'

const NotFound = () => {
  const { formatMessage } = useIntl()

  return (
    <>
      <Header />
      <div className="mt-20">
        <div className="uppercase text-center text-2xl">
          {formatMessage({ id: '404Pg' })}
        </div>
      </div>
      <div className="w-1/4 mt-16 mb-20 block mx-auto m-0 text-center">
        <Link to="/">
          <button
            type="button"
            className="py-2 px-4 flex justify-center items-center bg-blue text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
          >
            {formatMessage({ id: '404back' })}
          </button>
        </Link>
      </div>
    </>
  )
}
export default NotFound
