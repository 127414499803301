/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from 'formik'
import { useIntl } from 'react-intl'
import Error from '../atoms/Error'
import FieldLabel from '../atoms/FieldLabel'
import TextField from './Inputs/TextField'
import MediaField from './Inputs/MediaField'
import DropdownField from './Inputs/DropdownField'
import DateField from './Inputs/DateField'
import SwitchField from './Inputs/SwitchField'

const txtFieldClasses =
  'w-auto text-lg md:text-xl text-lightGray3 bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0'

const FieldGroup = ({ fields, title }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <h3 className="text-l md:text-xl font-semibold text-lightGray5 text-center mt-10">
        {formatMessage({ id: title })}
      </h3>
      {fields.map((fld) => {
        let field
        switch (fld.__component) {
          case 'fields.text-field':
            field = <TextField {...fld} classes={txtFieldClasses} />
            break
          case 'fields.media-field':
            field = (
              <Field name={fld.fieldName}>
                {({ field, form: { setFieldValue } }) => (
                  <MediaField
                    field={field}
                    setFieldValue={setFieldValue}
                    fieldLabel={fld.fieldLabel}
                  />
                )}
              </Field>
            )
            break
          case 'fields.rich-text-field':
            field = (
              <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                <FieldLabel
                  label={fld.fieldLabel}
                  isRequired={fld.isRequired}
                />
                <div className="inline-flex items-center justify-between">
                  <Field
                    name={fld.fieldName}
                    component="textarea"
                    className="text-area w-full"
                    rows="6"
                    placeholder={formatMessage({ id: 'max300' })}
                  />
                </div>
              </label>
            )
            break
          case 'fields.dropdown-field':
            field = <DropdownField {...fld} />
            break
          case 'fields.date-field':
            field = <DateField {...fld} />
            break
          case 'fields.boolean-field':
            field = <SwitchField {...fld} />
            break
        }
        return (
          <div key={`${fld.id}-${fld.fieldName}`}>
            {field}
            <Error fieldName={fld.fieldName} />
          </div>
        )
      })}
    </>
  )
}

export default FieldGroup
