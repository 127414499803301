import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ProcessWhite from '../../img/process_white.svg'
import ProcessBlue from '../../img/process_blue.svg'
import FinishedBlue from '../../img/finished_blue.svg'
import FinishedWhite from '../../img/finished_white.svg'
import {
  listSurveys,
  getLanguage,
  getSurveys,
  countSurveys,
  fetchSurveysCounts,
  getSurveysCounts,
} from '@mzaednica/core'
import withAuthentication from '../../utils/withAuthentication'
import { useIntl } from 'react-intl'
import SurveyCard from 'components/Surveys/SurveyCard'
import { ESurveyStatuses } from './utils/consts'

const Surveys = ({
  listSurveys,
  surveysList,
  language,
  countSurveys,
  fetchSurveysCounts,
  surveysCounts,
}) => {
  const [currentTab, setCurrentTab] = useState(ESurveyStatuses.ACTIVE)
  const { formatMessage } = useIntl()

  const { surveysCountsData } = surveysCounts

  const handleSurveyListing = useCallback(() => {
    switch (currentTab) {
      case ESurveyStatuses.ACTIVE:
        listSurveys({
          status: 'active',
        })
        break
      case ESurveyStatuses.PAST:
        listSurveys({
          status: 'past',
        })
        break
      default:
        listSurveys({
          status: 'planned',
        })
        break
    }
  }, [currentTab, listSurveys])

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchSurveysCounts()
    handleSurveyListing()
  }, [
    countSurveys,
    currentTab,
    fetchSurveysCounts,
    language,
    handleSurveyListing,
  ])

  const { listData } = surveysList

  return (
    <section className="pt-8  pb-40">
      <div className="container flex items-center justify-center space-x-5 flex-wrap mb-7">
        <button
          className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
            currentTab === ESurveyStatuses.ACTIVE
              ? 'text-white bg-blue'
              : 'text-lightGray2 bg-lightGray1 focus:outline-none'
          }`}
          onClick={() => setCurrentTab(ESurveyStatuses.ACTIVE)}
        >
          <img
            src={
              currentTab === ESurveyStatuses.ACTIVE ? ProcessWhite : ProcessBlue
            }
            alt="Progress icon"
            width="28"
            height="auto"
          />
          <span className="ml-4 md:ml-8">
            {formatMessage({ id: 'active' })}
            <span className="ml-2">{surveysCountsData.activeSurveys}</span>
          </span>
        </button>
        <button
          className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
            currentTab === ESurveyStatuses.PAST
              ? 'text-white bg-blue'
              : 'text-lightGray2 bg-lightGray1 focus:outline-none'
          }`}
          onClick={() => setCurrentTab(ESurveyStatuses.PAST)}
        >
          <img
            src={
              currentTab === ESurveyStatuses.PAST ? FinishedWhite : FinishedBlue
            }
            alt="Progress icon"
            width="28"
            height="auto"
          />
          <span className="ml-4 md:ml-8">{formatMessage({ id: 'past' })}</span>
          <span className="ml-2">{surveysCountsData.passedSurveys}</span>
        </button>
        <button
          className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
            currentTab === ESurveyStatuses.PLANNED
              ? 'text-white bg-blue'
              : 'text-lightGray2 bg-lightGray1 focus:outline-none'
          }`}
          onClick={() => setCurrentTab(ESurveyStatuses.PLANNED)}
        >
          <img
            src={
              currentTab === ESurveyStatuses.PLANNED
                ? ProcessWhite
                : ProcessBlue
            }
            alt="Progress icon"
            width="28"
            height="auto"
          />
          <span className="ml-4 md:ml-8">
            {formatMessage({ id: 'plannedSurveys' })}
          </span>
          <span className="ml-2">{surveysCountsData.plannedSurveys}</span>
        </button>
      </div>
      <>
        {surveysList.listData.length !== 0 ? (
          <div className="container gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 max-w-4xl">
            {listData.map((item, idx) => (
              <SurveyCard survey={item} key={idx} />
            ))}
          </div>
        ) : (
          <div className="text-center">
            {formatMessage({ id: 'noSurveys' })}
          </div>
        )}
      </>
    </section>
  )
}

const mapStateToProps = (state) => ({
  surveysList: getSurveys(state),
  language: getLanguage(state),
  surveysCounts: getSurveysCounts(state),
})

const mapDispatchToProps = {
  listSurveys,
  countSurveys,
  fetchSurveysCounts,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(Surveys),
)
