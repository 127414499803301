import * as yup from 'yup'

export const SurveySchemaQuestion = yup.object().shape({
  survey: yup.number(),
  question: yup.number(),
  options: yup
    .array()
    .of(yup.number())
    .when('type', {
      is: (type) => type === 'single_choice',
      then: yup
        .array()
        .of(yup.number())
        .min(1, 'requiredRadioButton')
        .required('requiredRadioButton'),
      otherwise: yup.array().notRequired(),
    })
    .when('type', {
      is: (type) => type === 'multiple_choice',
      then: yup
        .array()
        .of(yup.number())
        .min(1, 'requiredCheckBox')
        .required('requiredCheckBox'),
      otherwise: yup.array().notRequired(),
    }),
  input_response: yup.string().when('type', {
    is: (type) => type === 'input',
    then: yup.string().trim().required('requiredInput'),
    otherwise: yup.string().notRequired(),
  }),
})

export const SurveySchema = yup.object().shape({
  questions: yup.array(SurveySchemaQuestion),
})
