import moment from 'moment'

const NotificationCard = ({ notification }) => (
  <>
    <div className="bg-white max-w-full mx-auto px-18 py-9 rounded-xl shadow-xl">
      <div className="font-semibold mb-2">{notification.title}</div>
      <div className="mb-2">
        {moment(notification.created_at).format('DD.MM.YYYY')}
      </div>
      <div>{notification.body}</div>
    </div>
  </>
)

export default NotificationCard
