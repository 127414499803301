export const generateRandomColor = (
  numOptions,
  fixedColors = ['#1FF8A4', '#0B5CAF', '#18B4AA', '#F4D03F'],
) => {
  const colors = []
  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  for (let i = 0; i < numOptions; i++) {
    if (i < fixedColors.length) {
      colors.push(fixedColors[i])
    } else {
      colors.push(generateRandomColor())
    }
  }
  return colors
}
