import { useState } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'
import showPassword from '../../img/showPassword.png'
import hiddePassword from '../../img/hiddePassword.png'

export const PasswordInput = ({ field, ...props }) => {
  const [to_text, toggleType] = useState(true)
  const { formatMessage } = useIntl()

  const toggleInputType = () => {
    toggleType(!to_text)
  }
  return (
    <div className="w-full flex flex-row items-center">
      <Field
        {...field}
        {...props}
        type={to_text ? 'password' : 'text'}
        placeholder={formatMessage({ id: 'enterPass' })}
        className="py-2 bg-whiteSmoke mb-3 mt-2 rounded-md border-1 border-lightBlue inline-flex w-full focus:outline-none"
      />
      <div className="inline-flex w-auto justify-end items-center -ml-7 pr-3">
        <button
          type="button"
          className="outline-none focus:outline-none"
          onClick={() => toggleInputType()}
        >
          {to_text ? (
            <img src={hiddePassword} width="18" height="auto" alt="img" />
          ) : (
            <img src={showPassword} width="18" height="auto" alt="img" />
          )}
        </button>
      </div>
    </div>
  )
}
