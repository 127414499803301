import { useIntl } from 'react-intl'

const FieldLabel = ({ label, isRequired }) => {
  const { formatMessage } = useIntl()

  return (
    <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
      {formatMessage({ id: label })}
      {isRequired ? '*' : ''}
    </span>
  )
}

export default FieldLabel
