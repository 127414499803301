import { useCallback, useEffect, useRef, useState } from 'react'

const initialState = []

const MultipleMediaField = ({ field: { name, value }, setFieldValue }) => {
  const [fileState, setFileState] = useState(initialState)
  const fileUpload = useRef()

  const handleImageChange = (e) => {
    e.preventDefault()
    let newValue
    if (value) {
      newValue = [...value, ...e.target.files]
    } else {
      newValue = [...e.target.files]
    }
    setFieldValue(name, newValue)
  }

  const removeImage = useCallback(
    (idx, e) => {
      e.preventDefault()
      const newValue = [...value]
      newValue.splice(idx, 1)
      setFieldValue(name, newValue)
    },
    [name, setFieldValue, value],
  )

  useEffect(() => {
    const newStates = []
    if (value && value.length) {
      for (let i = 0; i < value.length; i++) {
        newStates.push({
          file: value[i],
        })
      }
    }
    setFileState(newStates)
  }, [value])

  return (
    <div
      className={
        fileState.length > 0
          ? 'flex flex-col justify-start items-start px-4 md:px-0'
          : 'hidden'
      }
    >
      <input
        ref={fileUpload}
        type="file"
        multiple
        onChange={handleImageChange}
        className="file-upload"
        id={name}
      />
      {fileState.map((item, idx) => (
        <div className="relative" key={idx}>
          {<p>{item.file.name}</p>}

          <button
            type="button"
            className="absolute top-0 -right-8 w-6 h-6 rounded-full text-white bg-green text-center font-bold"
            onClick={removeImage.bind(null, idx)}
          >
            &#10005;
          </button>
        </div>
      ))}
    </div>
  )
}

export default MultipleMediaField
