import closeIcon from '../../img/close-icon.svg'
export const AlertDialog = ({ title, description, open, onClose }) => (
  <>
    {open ? (
      <div
        className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
        onClick={onClose}
        role="button"
        tabIndex={0}
        onKeyDown={onClose}
      >
        <div className="bg-white rounded-lg border-4 ">
          <div className="w-full flex justify-end pr-4">
            <button className="mt-4 bg-blue-500 p-2 rounded" onClick={onClose}>
              <img alt="someAlt" src={closeIcon} className="w-4" />
            </button>
          </div>
          <div className="p-4">
            <p className="text-lg font-semibold">{title}</p>
            <p className="text-lg font-semibold">{description}</p>
          </div>
        </div>
      </div>
    ) : null}
  </>
)
