import moment from 'moment'

import parseTime from '../utils/parseTime'
import TickIcon from '../img/tick.svg'

const ConfirmedMeeting = ({ meetingInfo }) => {
  const {
    meetingDate,
    meetingStartTime,
    meetingEndTime,
    surname,
    name,
    motherName,
    fatherName,
  } = meetingInfo
  return (
    <div className="flex flex-col relative font-semibold bg-lightGray4 rounded-lg flex-wrap md:flex-nowrap p-7 mt-7">
      <img
        alt="someAlt"
        src={TickIcon}
        width="60"
        height="auto"
        className="absolute top-4 right-4"
      />
      <div>
        <span>{moment(meetingDate).format('D MMMM YYYY')}</span> -{' '}
        <span>
          {parseTime(meetingStartTime)}-{parseTime(meetingEndTime)}
        </span>
      </div>
      <div>
        {surname} {name}, {fatherName}, {motherName}
      </div>
      <div className="pt-7 font-semibold text-xl">
        <p>Вашиот термин е потврден.</p>
        <p>Ве молиме бидете на време во училиштето.</p>
      </div>
    </div>
  )
}

export default ConfirmedMeeting
