import { Bar } from 'react-chartjs-2'
import { useIntl } from 'react-intl'

export const MultipleChoiceQuestionStatistics = ({ question }) => {
  const { formatMessage } = useIntl()

  const labels = question.options.map(
    (option) => `${option.option} (${option.percentage})`,
  )
  const data = question.options.map((option) => option.count)

  const questionAnswer = data.reduce(
    (acc, currentValue) => acc + currentValue,
    0,
  )

  const stepSize = questionAnswer > 20 ? (questionAnswer > 100 ? 10 : 5) : 1

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Responses',
        backgroundColor: '#0B5CAF',
        data,
      },
    ],
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full mb-12">
      <div className="text-lg mt-10">{`${formatMessage({
        id: 'question',
      })}${question.question}`}</div>
      <div className="text-sm text-gray-600 mb-4">
        {formatMessage({ id: 'responsesSum' })} {question.totalResponses}
      </div>
      <div className="flex justify-center items-center mx-auto h-96 w-100">
        <Bar
          data={chartData}
          width={280}
          height={280}
          options={{
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: stepSize,
                    callback: function (value) {
                      return Number.isInteger(value) ? value : null
                    },
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  )
}
