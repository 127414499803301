import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import 'share-api-polyfill'

import App from './App'
import createStore from './store'
import I18nProvider from './i18n/settings/provider'
import { ConfigurationProvider } from '@mzaednica/core'
import { hydrate } from 'react-dom'

const store = createStore(window.__PRELOADED_STATE__)

hydrate(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <I18nProvider>
          <ConfigurationProvider
            configuration={{ baseUrl: process.env.RAZZLE_API_URL }}
          >
            <App />
          </ConfigurationProvider>
        </I18nProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}
