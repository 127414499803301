/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from 'formik'

import FieldLabel from '../../atoms/FieldLabel'

const SwitchField = ({ fieldName, fieldLabel, isRequired }) => (
  <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
    <FieldLabel label={fieldLabel} isRequired={isRequired} />
    <div className="inline-flex items-center justify-end">
      <Field type="checkbox" name={fieldName} />
    </div>
  </label>
)

export default SwitchField
