import { useEffect, useState, useCallback } from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import * as yup from 'yup'

import { fetchEnrolment, resetEnrolmentRequest } from '@mzaednica/core'
import CreateEnrolmentRequest from '../../components/Enrolments/CreateEnrolmentRequest'

import './KindergartenEnrolment.css'
import { useIntl } from 'react-intl'

const KindergartenEnrolment = ({
  fetchEnrolment,
  kindergartens,
  resetEnrolmentRequest,
}) => {
  const [id, setId] = useState('')
  const [, setEnrolment] = useState(null)
  const [validationSchema, setValidationSchema] = useState(yup.object())
  const [initialValues, setInitialValues] = useState({})
  const history = useHistory()
  const { formatMessage } = useIntl()

  const handleChange = (e) => {
    setId(e.target.value)
    resetEnrolmentRequest()
  }

  const getInitialData = useCallback(async () => {
    const enrolment = await fetchEnrolment(2)
    setEnrolment(enrolment)

    if (enrolment) {
      const newInitValues = {}
      const objectShape = {}
      enrolment.fields.forEach((field) => {
        newInitValues[field.fieldName] = null
        let fieldValidator
        switch (field.__component) {
          case 'fields.text-field':
            fieldValidator = yup.string().ensure()
            if (field.isRequired) {
              fieldValidator = fieldValidator.required(
                formatMessage({ id: 'reqField' }),
              )
            }
            switch (field.validation) {
              case 'email':
                fieldValidator = fieldValidator.email(
                  formatMessage({ id: 'invalidemail' }),
                )
                break
              case 'embg':
                fieldValidator = fieldValidator.matches(
                  // eslint-disable-next-line no-undef
                  embgRegex,
                  formatMessage({ id: 'invalidEMBG' }),
                )
                break
            }
            break
          case 'fields.media-field':
            if (field.isRequired) {
              fieldValidator = yup
                .mixed()
                .required(formatMessage({ id: 'reqField' }))
                .typeError(formatMessage({ id: 'reqField' }))
            }
            break
          case 'fields.dropdown-field':
            if (field.isRequired) {
              fieldValidator = yup
                .mixed()
                .required(formatMessage({ id: 'reqField' }))
                .typeError(formatMessage({ id: 'reqField' }))
            }
            break
        }
        if (fieldValidator) {
          objectShape[field.fieldName] = fieldValidator
        }
      })
      setValidationSchema(
        yup.object().shape({
          ...objectShape,
        }),
      )
      setInitialValues({
        ...newInitValues,
      })
    }
  }, [fetchEnrolment, formatMessage])

  useEffect(() => {
    // enrolment with id 2 is for kindergartens
    getInitialData()
  }, [getInitialData])

  return (
    <div className="flex flex-col justify-center">
      <div>
        <h4 className="text-sm text-center md:text-2xl font-bold uppercase mt-10">
          {formatMessage({ id: 'enrollChildKinder' })}
        </h4>
        <div className="text-sm text-center text-gray-500 md:text-xl font-semibold mt-10">
          <p>{formatMessage({ id: 'enrollmentKidsInInstitutionYer' })}</p>
          <p>{formatMessage({ id: 'enrollmentRequestDirectM' })}</p>
          <p>{formatMessage({ id: 'docsYouNeedToAttach' })}</p>
          <p>- {formatMessage({ id: 'familyDoctorDownloadInfo' })}</p>
          <p>- {formatMessage({ id: 'copyOfHealthCardKid' })}</p>
          <p>- {formatMessage({ id: 'birthCardChild' })}</p>
          <p>- {formatMessage({ id: 'workingOrNonWorkingParents' })}</p>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        {() => (
          <Form>
            <div id={id ? 'valueSet' : null}>
              <FormControl variant="standard">
                <Select
                  value={id}
                  onChange={handleChange}
                  disableUnderline={true}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>
                      {formatMessage({ id: 'chooseKindergartenAndBuilding' })}
                    </em>
                  </MenuItem>
                  {kindergartens.map((kindergarten, idx) => (
                    <MenuItem value={kindergarten.id} key={idx}>
                      <div className="menuItemContent">
                        <p>{kindergarten.name}</p>
                        <p>{kindergarten.municipality.name}</p>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Form>
        )}
      </Formik>
      {id && (
        <CreateEnrolmentRequest id={id} claimId={null} history={history} />
      )}
    </div>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  fetchEnrolment,
  resetEnrolmentRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KindergartenEnrolment)
