import { useIntl } from 'react-intl'

import { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import Footer from '../components/Footer'
import Header from '../components/Header'
import PlayStore from '../img/play_store.svg'
import AppStore from '../img/app_store.svg'
import HeroAside from '../img/mobile-phones-new.png'

import SwiperCore, { Navigation, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'

import ReportCard from '../components/Reports/ReportCard'
import SubventionCard from '../components/Subventions/SubventionCard'

import {
  listIssues,
  listInterests,
  listSubventions,
  getIssues,
  getInterests,
  getSubventions,
  getLanguage,
  removeSupport,
  createSupport,
  getIssueCounts,
  getSupports,
  getUserDetails,
  getSupportedIssues,
  listSupportedIssues,
} from '@mzaednica/core'
import withAuthentication from '../utils/withAuthentication'
import { LANGUAGES } from '../i18n/settings/languages'

SwiperCore.use([Navigation, A11y])

const Homepage = ({
  listIssues,
  listInterests,
  listSubventions,
  issuesList,
  interestList,
  subventionsList,
  language,
  removeSupport,
  createSupport,
  supports,
  listSupportedIssues,
  userDetails,
}) => {
  const [issueCategoryFilter, setIssueCategoryFilter] = useState(null)
  const { formatMessage } = useIntl()

  useEffect(() => {
    listInterests()
    listSupportedIssues(userDetails?.userData?.id)

    listSubventions({ _sort: 'updated_at:DESC' })
    window.scrollTo(0, 0)
  }, [
    listInterests,
    listSubventions,
    listSupportedIssues,
    userDetails?.userData?.id,
  ])

  useEffect(() => {
    const filter = {}
    if (issueCategoryFilter) {
      filter.category = issueCategoryFilter
    }
    listIssues(filter)
  }, [issueCategoryFilter, listIssues])

  const toggleCategoryFilter = useCallback(
    (category) => {
      if (issueCategoryFilter === category) {
        setIssueCategoryFilter(null)
      } else {
        setIssueCategoryFilter(category)
      }
    },
    [issueCategoryFilter],
  )

  return (
    <>
      <section className="h-full min-h-screen bg-header bg-top bg-no-repeat bg-cover">
        <Header nobg />
        <div className="container md:flex md:items-center h-full md:h-screenFix px-5 lg:px-0 py-10 md:py-0">
          <div className="w-full px-0 md:px-4">
            <h1 className="text-3xl md:text-5xl text-white font-medium">
              {formatMessage({ id: 'inclCommunity' })}
              <br />
              <strong>{formatMessage({ id: 'moreEfficient' })}</strong>
            </h1>
            <figure className="sm:flex mt-8 md:mt-16 sm:space-x-4">
              <a
                href="https://apps.apple.com/mk/app/%D0%BC%D0%B7%D0%B0%D0%B5%D0%B4%D0%BD%D0%B8%D1%86%D0%B0/id1451226615"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppStore}
                  alt="Download on the AppStore"
                  width="200"
                  height="auto"
                  className="mb-4 md:mb-0"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.blink4221.mzaednica"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={PlayStore}
                  alt="Get it on Google Play"
                  width="200"
                  height="auto"
                  className="mb-4 md:mb-0"
                />
              </a>
            </figure>
          </div>

          <div className="w-full flex md:justify-end px-0 md:px-6 mt-0 md:mt-10">
            <img
              src={HeroAside}
              alt="Get it on Google Play"
              height="auto"
              className="h-auto"
            />
          </div>
        </div>
      </section>

      <section className="container py-14">
        <div className="flex items-center justify-center space-x-5 flex-wrap">
          {interestList.listData.map((item) => (
            <button
              key={item.id}
              onClick={() => {
                toggleCategoryFilter(item.id)
              }}
              className={`w-auto inline-flex items-center justify-center border border-transparent text-sm md:text-base font-semibold rounded-full bg-blue hover:bg-opacity-80 py-1.5 px-3 mb-4 ${
                issueCategoryFilter === item.id
                  ? ' text-white bg-blue '
                  : 'text-darkGray bg-lightGray4'
              }`}
            >
              {language === LANGUAGES.MK ? item.name : item.name_sq}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap justify-between mt-10 md:mt-12 px-6 md:px-4">
          <h2 className="text-2xl font-extrabold">
            {formatMessage({ id: 'report' })}
          </h2>
          <NavLink
            to="/reports"
            activeStyle={{
              fontWeight: 'bold',
              color: 'text-blue',
            }}
            className="text-lg font-extrabold	text-blue underline"
          >
            {formatMessage({ id: 'seeMore' })}
          </NavLink>
        </div>
        <div className="container mt-6">
          {/* Swiper js */}
          <Swiper
            spaceBetween={0}
            slidesPerView={3}
            navigation
            breakpoints={{
              1280: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 2,
              },
              0: {
                slidesPerView: 1,
              },
            }}
          >
            {issuesList.listData.map((item, idx) => (
              <SwiperSlide className="p-5" key={idx}>
                <ReportCard
                  key={item.id}
                  report={item}
                  supported={supports.supportedMap[item.id]}
                  createSupport={createSupport}
                  removeSupport={removeSupport}
                  language={language}
                  listIssues={listIssues}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex flex-wrap justify-between mt-10 md:mt-12 px-6 md:px-4">
          <h2 className="text-2xl font-extrabold">
            {formatMessage({ id: 'subventions' })}
          </h2>
          <Link
            to="/services"
            className="text-lg font-extrabold	text-blue underline"
          >
            {formatMessage({ id: 'seeMore' })}
          </Link>
        </div>
        <div className="container mt-4 md:mt-6">
          <Swiper
            spaceBetween={0}
            slidesPerView={3}
            navigation
            breakpoints={{
              1280: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 2,
              },
              0: {
                slidesPerView: 1,
              },
            }}
          >
            {subventionsList.listData.map((item, idx) => (
              <SwiperSlide className="p-5" key={idx}>
                <SubventionCard subvention={item} language={language} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  issuesList: getIssues(state),
  interestList: getInterests(state),
  subventionsList: getSubventions(state),
  language: getLanguage(state),
  counts: getIssueCounts(state),
  supports: getSupports(state),
  userDetails: getUserDetails(state),
  supportedIssues: getSupportedIssues(state),
})

const mapDispatchToProps = {
  listIssues,
  listInterests,
  listSubventions,
  listSupportedIssues,
  removeSupport,
  createSupport,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(Homepage),
  false,
  true,
)
