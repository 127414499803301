/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useIntl } from 'react-intl'

import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { Helmet } from 'react-helmet-async'
import Footer from '../../components/Footer'
import {
  fetchSubvention,
  getSubventionDetails,
  getLanguage,
} from '@mzaednica/core'
import Header from '../../components/Header'
import ShareGray from '../../img/share_gray.svg'
import findImageWithQuality from '../../utils/findImageWithQuality'
import { LANGUAGES } from '../../i18n/settings/languages'
import ReactMarkdown from 'react-markdown'

const SubventionDetails = ({
  fetchSubvention,
  subventionDetails,
  language,
}) => {
  let { id } = useParams()
  let details = subventionDetails.subventionData

  useEffect(() => {
    if (!details || `${details.id}` !== id) {
      fetchSubvention(id)
    }
  }, [details, fetchSubvention, id])

  const isExpired = details && moment(details.dueDate).isBefore(moment())

  const share = (e) => {
    e.preventDefault()
    if (navigator.share) {
      navigator
        .share({
          url: `${process.env.RAZZLE_WEB_URL}/newsDetails/${id}`,
          // extra, optional options
          fbId: `${process.env.RAZZLE_FACEBOOK_ID}`,
          hashtags: ['mZaednica'],
        })
        // eslint-disable-next-line no-unused-vars, no-console
        .then(() => console.log('Yay, you shared it :)'))
        .catch((error) =>
          console.error("Oh noh! You couldn't share it! :'(\n", error),
        )
    }
  }

  const { formatMessage } = useIntl()
  return (
    <>
      <Header />
      {details && (
        <>
          <Helmet>
            <>
              <meta property="og:title" content={details.title} />
              <meta property="og:description" content={details.description} />
              <meta
                property="og:image"
                content={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  details.featuredImage,
                  'medium',
                )}`}
              />
            </>
          </Helmet>
          <div className="container mt-5 mb-20 max-w-3xl">
            {details.featuredImage && (
              <img
                className="object-contain h-64 w-full mb-10"
                src={`${process.env.RAZZLE_API_URL}${findImageWithQuality(
                  details.featuredImage,
                  'medium',
                )}`}
                alt=""
              />
            )}
            <div className="text-center uppercase text-lg mb-10">
              {language === LANGUAGES.MK
                ? details.title
                : details.title_sq ?? details.title}
            </div>
            <div className="font-bold p-5 md:p-2">
              {moment(details.dateFrom).format('DD.MM.YYYY')} -{' '}
              {moment(details.dueDate).format('DD.MM.YYYY')}
            </div>
            <div className="text-left p-5 md:p-2">
              <ReactMarkdown>
                {language === LANGUAGES.MK
                  ? details.description
                  : details.description_sq ?? details.description}
              </ReactMarkdown>
            </div>
            {!!details.link && (
              <div className="mt-3 p-5 md:p-2">
                <a
                  href={details.link}
                  target="_blank"
                  className="text-blue"
                  rel="noreferrer"
                >
                  {details.link ? details.link : ''}
                </a>
              </div>
            )}
            {!!details.requiredDocs && (
              <div className="mt-3 p-5 md:p-2">
                Кликни{' '}
                <a
                  href={`${process.env.RAZZLE_API_URL}${details.requiredDocs.url}`}
                  target="_blank"
                  className="text-blue"
                  rel="noreferrer"
                >
                  тука
                </a>{' '}
                за да превземеш потребен документ!
              </div>
            )}
            <div className="flex flex-row mt-10 items-center gap-4">
              {!isExpired && (
                <Link to={`/createSubventionRequest/${id}`}>
                  <button
                    type="submit"
                    className="bg-blue text-white font-bold py-2 px-7 border-4 border-blue rounded-lg focus:outline-none"
                  >
                    {formatMessage({ id: 'signup' })}
                  </button>
                </Link>
              )}
              <div className="cursor-pointer" onClick={share}>
                <img alt="someAlt" src={ShareGray} className="w-7 inline" />
                <button
                  type="button"
                  className="text-md font-semibold text-darkGray1 focus:outline-none"
                >
                  {formatMessage({ id: 'share' })}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  subventionDetails: getSubventionDetails(state),
  language: getLanguage(state),
})

const mapDispatchToProps = {
  fetchSubvention,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubventionDetails)
