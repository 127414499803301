export default (upload, quality = 'thumbnail') => {
  if (!!upload && !upload.url) {
    return null
  }

  let bestUrl = upload?.url
  if (upload?.formats?.['large']) {
    bestUrl = upload.formats['large'].url
  }
  if (quality === 'large') {
    return bestUrl
  }

  if (upload?.formats?.['medium']) {
    bestUrl = upload.formats['medium'].url
  }
  if (quality === 'medium') {
    return bestUrl
  }

  if (upload?.formats?.['small']) {
    bestUrl = upload.formats['small'].url
  }
  if (quality === 'small') {
    return bestUrl
  }

  if (upload?.formats?.['thumbnail']) {
    bestUrl = upload.formats['thumbnail'].url
  }
  return bestUrl
}
