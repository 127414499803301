import { useIntl } from 'react-intl'
import { useCallback, useEffect, useState, useRef } from 'react'
import { Field, Form, Formik, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import * as yup from 'yup'
import * as Scroll from 'react-scroll'
import dayjs from 'dayjs'

import {
  fetchEnrolment,
  fetchEnrolmentClaim,
  createEnrolmentClaim,
  updateEnrolmentClaimWeb,
  fetchEducationalFacility,
  getEnrolmentDetails,
  getEnrolmentRequestDetails,
  getEducationalFacilityDetails,
  embgRegex,
  uploadFile,
  getUserDetails,
} from '@mzaednica/core'
import FieldGroup from '../FieldGroup'
import Docs from '../Inputs/Docs'
import parseDate from '../../utils/parseDate'

const CreateEnrolmentRequest = ({
  id,
  claimId,
  history,
  fetchEnrolment,
  fetchEducationalFacility,
  enrolmentDetails,
  enrolmentClaimDetails,
  educationalFacilityDetails,
  updateEnrolmentClaimWeb,
  createEnrolmentClaim,
  fetchEnrolmentClaim,
  userDetails,
  chosenDate,
  submitionType,
}) => {
  const { formatMessage } = useIntl()
  const initialValuesRef = useRef(null)

  const getInitialValues = useCallback(() => {
    const enrolmentRequestData =
      enrolmentClaimDetails.enrolmentRequestData?.[0] || {}
    const embg = enrolmentRequestData?.embg || ''
    const name = `${enrolmentRequestData?.name || ''} ${
      enrolmentRequestData?.surname || ''
    }`.trim()
    let dateBirth = ''

    if (embg) {
      const day = embg.slice(0, 2)
      const month = embg.slice(2, 4)
      let year = embg.slice(4, 7)
      year = year.startsWith('9') ? `1${year}` : `2${year}`
      dateBirth = `${year}-${month}-${day}`
    }

    return {
      familyDoctorProof: [],
      healthCardCopy: [],
      birthCertificate: [],
      employmentCertificate: [],
      termsAndConditions: false,
      name,
      embg,
      dateBirth,
    }
  }, [enrolmentClaimDetails])

  const [initialValues, setInitialValues] = useState(() => getInitialValues())
  const [validationSchema, setValidationSchema] = useState(yup.object())
  const scroll = Scroll.animateScroll

  const getInitialData = useCallback(async () => {
    try {
      const enrolment = await fetchEnrolment(claimId ? 1 : 2)
      let enrolmentFields = enrolment.fields

      if (submitionType === 'byHand') {
        enrolmentFields = enrolment.fields.filter(
          (field) => field.__component !== 'fields.multiple-media-field',
        )
      }

      if (enrolment) {
        const newInitValues = { ...initialValuesRef.current }
        const objectShape = {}

        enrolmentFields.forEach((field) => {
          let fieldValidator
          switch (field.__component) {
            case 'fields.text-field':
              fieldValidator = yup.string().ensure()
              if (field.isRequired) {
                fieldValidator = fieldValidator.required(
                  formatMessage({ id: 'reqField' }),
                )
              }
              switch (field.validation) {
                case 'email':
                  fieldValidator = fieldValidator.email(
                    formatMessage({ id: 'invalidemail' }),
                  )
                  break
                case 'embg':
                  fieldValidator = fieldValidator.matches(
                    embgRegex,
                    formatMessage({ id: 'invalidEMBG' }),
                  )
                  break
              }
              if (!newInitValues[field.fieldName]) {
                newInitValues[field.fieldName] = ''
              }
              break
            case 'fields.date-field':
              fieldValidator = yup
                .date()
                .required(formatMessage({ id: 'reqField' }))
              if (!newInitValues[field.fieldName]) {
                newInitValues[field.fieldName] = ''
              }
              break
            case 'fields.media-field':
              if (field.isRequired) {
                fieldValidator = yup
                  .mixed()
                  .required(formatMessage({ id: 'reqField' }))
                  .typeError(formatMessage({ id: 'reqField' }))
              }
              if (!newInitValues[field.fieldName]) {
                newInitValues[field.fieldName] = []
              }
              break
            case 'fields.multiple-media-field':
              if (field.isRequired) {
                fieldValidator = yup
                  .mixed()
                  .required(formatMessage({ id: 'reqField' }))
                  .typeError(formatMessage({ id: 'reqField' }))
              }
              if (!newInitValues[field.fieldName]) {
                newInitValues[field.fieldName] = []
              }
              break
            case 'fields.dropdown-field':
              if (field.isRequired) {
                fieldValidator = yup
                  .mixed()
                  .required(formatMessage({ id: 'reqField' }))
                  .typeError(formatMessage({ id: 'reqField' }))
              }
              if (!newInitValues[field.fieldName]) {
                newInitValues[field.fieldName] = ''
              }
              break
          }
          if (fieldValidator) {
            objectShape[field.fieldName] = fieldValidator
          }
        })

        setValidationSchema(
          yup.object().shape({
            ...objectShape,
            termsAndConditions: yup
              .boolean()
              .required()
              .oneOf([true], formatMessage({ id: 'reqField' })),
          }),
        )
        setInitialValues(newInitValues)
      }
    } catch (error) {
      console.error('Failed to fetch initial data:', error)
    }
  }, [claimId, fetchEnrolment, formatMessage, submitionType])

  useEffect(() => {
    scroll.scrollToTop()
    if (claimId && claimId !== 'new')
      fetchEnrolmentClaim(claimId).catch((error) =>
        console.error('Failed to fetch enrolment claim:', error),
      )
  }, [claimId, fetchEnrolmentClaim, scroll])

  useEffect(() => {
    if (enrolmentClaimDetails.isFetched && !initialValuesRef.current) {
      const values = getInitialValues()
      initialValuesRef.current = values
      setInitialValues(values)
    }
  }, [enrolmentClaimDetails, getInitialValues])

  useEffect(() => {
    if (enrolmentClaimDetails.isFetched) {
      getInitialData()
    }
  }, [getInitialData, enrolmentClaimDetails.isFetched])

  useEffect(() => {
    const geteducationalFacilityDetails = async () => {
      try {
        await fetchEducationalFacility(id)
      } catch (error) {
        console.error('Failed to fetch educational facility details:', error)
      }
    }
    geteducationalFacilityDetails()
  }, [fetchEducationalFacility, id])

  const submitForm = useCallback(
    async (values) => {
      if (!userDetails || !userDetails.userData) {
        console.error('User details are not available')
        return
      }

      const dataFields = []
      let enrolmentFields = enrolmentDetails.enrolmentData.fields
      if (submitionType === 'byHand') {
        enrolmentFields = enrolmentDetails.enrolmentData.fields.filter(
          (field) => field.__component !== 'fields.multiple-media-field',
        )
      }

      for (let i = 0; i < enrolmentFields.length; ++i) {
        if (enrolmentFields[i].__component === 'fields.multiple-media-field') {
          continue
        }
        const field = enrolmentFields[i]
        dataFields.push({
          ...field,
          value: values[field.fieldName],
          id: undefined,
        })
      }

      for (let i = 0; i < enrolmentFields.length; ++i) {
        if (enrolmentFields[i].__component !== 'fields.multiple-media-field') {
          continue
        }
        for (let fld of values[enrolmentFields[i].fieldName]) {
          const formData = new FormData()
          formData.append('files', fld)
          const files = await uploadFile(formData).catch((error) => {
            console.error('Failed to upload file:', error)
            return []
          })
          if (files.length > 0) {
            dataFields.push({
              ...enrolmentFields[i],
              value: files[0].id,
              id: undefined,
            })
          }
        }
      }

      const params = {
        user: userDetails.userData.id,
        educational_facility: id,
        fields: dataFields,
        status: 'submitted',
      }

      try {
        if (claimId) {
          if (claimId === 'new') {
            const meetingStartTime = chosenDate
            const meetingEndTime = dayjs(meetingStartTime).add(
              educationalFacilityDetails.educationalFacilityData
                .meetingDuration,
              'minutes',
            )
            const meetingDate = parseDate(chosenDate)
            const embg = dataFields.find(
              (field) => field.fieldName === 'embg',
            ).value
            await createEnrolmentClaim({
              enrolment: 1,
              meetingDate,
              meetingStartTime,
              meetingEndTime,
              embg,
              ...params,
            })
          } else {
            await updateEnrolmentClaimWeb(claimId, {
              enrolment: 1,
              meetingStartTime: chosenDate,
              ...params,
            })
          }
        } else {
          await createEnrolmentClaim({
            enrolment: 2,
            municipality: userDetails.userData.municipality,
            ...params,
          })
        }
        history.push('/successfulEnrolment')
      } catch (error) {
        console.error('Failed to submit form:', error)
      }
    },
    [
      enrolmentDetails.enrolmentData,
      submitionType,
      userDetails,
      id,
      claimId,
      chosenDate,
      educationalFacilityDetails,
      createEnrolmentClaim,
      updateEnrolmentClaimWeb,
      history,
    ],
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ isValid, dirty, isSubmitting, values }) => (
          <Form className="container w-840 max-w-full md:pt-103 md:pb-48">
            <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center">
              {formatMessage({ id: 'enrolmentRequest' })}
            </h2>
            {enrolmentDetails.enrolmentData && (
              <>
                <FieldGroup
                  fields={enrolmentDetails.enrolmentData.fields.filter(
                    (field) =>
                      field.__component !== 'fields.multiple-media-field',
                  )}
                  title="basicInfo"
                />
                {!(submitionType === 'byHand') && (
                  <Docs
                    fields={enrolmentDetails.enrolmentData.fields.filter(
                      (field) =>
                        field.__component === 'fields.multiple-media-field',
                    )}
                  />
                )}
              </>
            )}
            <div className="mt-10">
              <label>
                <Field type="checkbox" name="termsAndConditions" />
                <small className="pl-2">
                  {formatMessage({ id: 'iAgreeEduInstitution' })}
                </small>
              </label>
              <div className="text-red">
                <ErrorMessage name="termsAndConditions" />
              </div>
            </div>

            <div className="flex justify-end mt-10 md:mt-14 px-4 md:px-0">
              <button
                type="submit"
                className="flex justify-center py-1.5 px-10 border border-transparent shadow-sm text-lg font-semibold rounded-xl text-white bg-blue disabled:opacity-20 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
                disabled={
                  !isValid ||
                  !dirty ||
                  isSubmitting ||
                  !values.termsAndConditions
                }
              >
                {formatMessage({ id: 'send' })}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = (state) => ({
  enrolmentDetails: getEnrolmentDetails(state),
  enrolmentClaimDetails: getEnrolmentRequestDetails(state),
  educationalFacilityDetails: getEducationalFacilityDetails(state),
  userDetails: getUserDetails(state),
})

const mapDispatchToProps = {
  fetchEnrolment,
  fetchEnrolmentClaim,
  fetchEducationalFacility,
  updateEnrolmentClaimWeb,
  createEnrolmentClaim,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateEnrolmentRequest)
