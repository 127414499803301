import moment from 'moment'

const enumerateDaysBetweenDates = (startDate, endDate) => {
  var dates = []

  var currDate = moment(startDate).startOf('day')
  var lastDate = moment(endDate).startOf('day')

  do {
    dates.push(currDate.clone().toDate())
  } while (currDate.add(1, 'days').diff(lastDate) <= 0)

  return dates
}

export default enumerateDaysBetweenDates
