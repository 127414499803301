import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import {
  fetchSurvey,
  fetchSurveyStatistics,
  getSurveyDetails,
  getSurveyStatistics,
} from '@mzaednica/core'
import { connect } from 'react-redux'
import { MultipleChoiceQuestionStatistics } from '../../components/Surveys/MultipleChoiceQuestionStatistics'
import { SingleChoiceQuestionStatistics } from '../../components/Surveys/SingleChoiceQuestionStatistics'
import { InputQuestionStatistics } from '../../components/Surveys/InputQuestionStatistics'
import { formatDateToDDMMYY } from '../../utils/formatDateToDDMMYY'
import Header from 'components/Header'

const SurveyStatisticsPage = ({
  fetchSurveyStatistics,
  surveyStatistics,
  fetchSurvey,
  surveyDetails,
}) => {
  const { formatMessage } = useIntl()
  let { id } = useParams()
  const { statistics, isFetching } = surveyStatistics
  const { surveyData } = surveyDetails || {}

  useEffect(() => {
    fetchSurveyStatistics(id)
    fetchSurvey(id)
  }, [fetchSurveyStatistics, fetchSurvey, id])

  if (isFetching) return null

  return (
    <>
      <Header />
      <div className="container mx-auto text-center pt-10">
        {statistics?.questions.length === 0 ? (
          <div className="text-2xl">{formatMessage({ id: 'noResults' })}</div>
        ) : (
          <>
            <div className="text-2xl">
              {formatMessage({ id: 'surveyResults' }) + ' - '}
              {statistics?.surveyTitle}
            </div>
            {surveyData && surveyData.dateFrom && surveyData.dateDue ? (
              <div className="date-header">{` ${formatDateToDDMMYY(
                surveyData.dateFrom,
              )} - ${formatDateToDDMMYY(surveyData.dateDue)}`}</div>
            ) : null}
            <div className="container mx-auto mt-10">
              {statistics?.questions.map((question, index) => {
                switch (question.type) {
                  case 'multiple_choice':
                    return (
                      <MultipleChoiceQuestionStatistics
                        key={index}
                        question={question}
                      />
                    )
                  case 'single_choice':
                    return (
                      <SingleChoiceQuestionStatistics
                        key={index}
                        question={question}
                      />
                    )
                  case 'input':
                    return (
                      <InputQuestionStatistics
                        key={index}
                        question={question}
                      />
                    )
                  default:
                    return null
                }
              })}
            </div>
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  surveyStatistics: getSurveyStatistics(state),
  surveyDetails: getSurveyDetails(state),
})

const mapDispatchToProps = {
  fetchSurveyStatistics,
  fetchSurvey,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyStatisticsPage)
