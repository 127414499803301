import * as yup from 'yup'
import { passwordRegex } from '../../../utils/regex/passwordRegex'

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'min6Chars')
    .max(70, 'passwordTooLong')
    .matches(passwordRegex, 'passwordMustContain')
    .required('RequiredInput'),
})
