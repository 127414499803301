import { useIntl } from 'react-intl'

export const InputQuestionStatistics = ({ question }) => {
  const { formatMessage } = useIntl()

  const { responses, question: questionText } = question

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full mb-16">
      <div className="text-lg">{`${formatMessage({
        id: 'question',
      })}${questionText}`}</div>
      <div className="text-sm text-gray-600 mb-4">
        {formatMessage({ id: 'responsesSum' })} {question.totalResponses}
      </div>
      <div className="space-y-2">
        {responses?.map((response, index) => (
          <div
            key={index}
            className="bg-gray-100 p-3 rounded-lg text-gray-800 flex items-center justify-between"
          >
            <span className="text-lg">{response}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
