import { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import MaskedInput from 'react-text-mask'
import { useIntl } from 'react-intl'

import { embgMask } from '../consts/inputMasks'
import SearchIcon from '../img/search.svg'

const SearchExistingRequest = ({ initialValues, onSubmitHandler }) => {
  const [isMasked, setIsMasked] = useState(true)

  const toggleMask = () => {
    setIsMasked(!isMasked)
  }

  const { formatMessage } = useIntl()

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
        {({ isSubmitting }) => (
          <Form className="flex flex-col mb-10 flex-wrap md:flex-nowrap px-5 mt-1">
            <div className="flex flex-row">
              <label className="grid grid-cols-2 bg-lightGray4 rounded-full py-3.5 px-4 w-full md:ml-3 mt-4 md:mt-0">
                <Field name="embg">
                  {({ field }) => {
                    let inputProps = {
                      ...field,
                      mask: embgMask,
                      autoComplete: 'off', // Ensure autocomplete is off
                    }
                    return (
                      <MaskedInput
                        {...inputProps}
                        className="w-auto text-lg md:text-xl bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                        placeholder={formatMessage({ id: 'enterEMBGChild' })}
                        type="text"
                        render={(ref, props) => (
                          <input
                            {...props}
                            ref={ref}
                            style={{
                              WebkitTextSecurity: isMasked ? 'disc' : 'none',
                              MozTextSecurity: isMasked ? 'disc' : 'none',
                              textSecurity: isMasked ? 'disc' : 'none',
                            }}
                          />
                        )}
                      />
                    )
                  }}
                </Field>
                <div className="inline-flex w-auto justify-end items-center">
                  <button
                    type="button"
                    className="pr-3 text-md font-bold text-blue disabled:opacity-20"
                    onClick={toggleMask}
                  >
                    {isMasked ? 'show' : 'hide'}
                  </button>
                  <button
                    type="submit"
                    className="pr-3 text-md font-bold text-blue disabled:opacity-20"
                    disabled={isSubmitting}
                  >
                    {formatMessage({ id: 'search' })}
                  </button>
                  <img
                    alt="someAlt"
                    src={SearchIcon}
                    width="30"
                    height="auto"
                  />
                </div>
              </label>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SearchExistingRequest
